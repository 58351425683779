import React, {useEffect, useState} from 'react';
import {StyleSheet} from 'react-native';
import {
  HStack,
  Box,
  Text,
  Heading,
  Skeleton,
  Pressable,
  Input,
  VStack,
  Stack,
  CheckIcon,
} from 'native-base';
import {Select} from '../../../Libs/CustomInputs';
import {createIconSetFromFontello} from 'react-native-vector-icons';
import Trans from '../../Components/Trans';
import main from '../../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import FormAction from '../../../Actions/FormAction';
import APIAction from '../../../Actions/APIAction';
import GeneralAction from '../../../Actions/GeneralAction';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const Address = props => {
  const [editAddress, setEditAddress] = useState(false),
    [address, setAddress] = useState({
      id: null,
      street: null,
      streetNumber: null,
      suite: null,
      zip: null,
      place: null,
      country: 'BE',
    }),
    [fullAddress, setFullAddress] = useState(null),
    [addressError, setAddressError] = useState(null),
    [countryOptions, setCountryOptions] = useState([]);
  useEffect(() => {
    const init = async () => {
      let countries = await GeneralAction.getCountries();

      let newCountryOptions = [];
      for (let [countryCode, country] of Object.entries(countries)) {
        newCountryOptions.push(
          <Select.Item value={countryCode} label={country} />,
        );
      }

      setCountryOptions(newCountryOptions);
    };

    if (countryOptions.length < 1) {
      init();
    }
  }, [countryOptions]);

  useEffect(() => {
    if (props.user && props.user.employee.address) {
      setAddress({
        id: props.user.employee.address.id,
        street: props.user.employee.address.street,
        streetNumber: props.user.employee.address.streetNumber,
        suite: props.user.employee.address.suite,
        zip: props.user.employee.address.zip,
        place: props.user.employee.address.place,
        country: props.user.employee.address.country.toUpperCase(),
      });
    }
  }, [props.user]);

  useEffect(() => {
    const init = async () => {
      let countries = await GeneralAction.getCountries();
      let country = countries[address.country.toUpperCase()];

      let newFullAddress = address.street;
      newFullAddress += ' ';
      newFullAddress += address.streetNumber;
      if (address.suite) {
        newFullAddress += address.suite;
      }
      newFullAddress += ', ';
      newFullAddress += address.zip;
      newFullAddress += ' ';
      newFullAddress += address.place;
      newFullAddress += ', ';
      newFullAddress += country;

      setFullAddress(newFullAddress);
    };
    if (
      address.street &&
      address.streetNumber &&
      address.zip &&
      address.place &&
      address.country
    ) {
      init();
    }
  }, [address]);

  const save = async () => {
    let valid = true;
    let errors = {};

    if (!FormAction.validateEmpty(address.street)) {
      valid = false;
      errors['street'] = <Trans>This field is required</Trans>;
    }
    if (!FormAction.validateEmpty(address.streetNumber)) {
      valid = false;
      errors['streetNumber'] = <Trans>This field is required</Trans>;
    }
    if (!FormAction.validateEmpty(address.zip)) {
      valid = false;
      errors['zip'] = <Trans>This field is required</Trans>;
    }
    if (!FormAction.validateEmpty(address.place)) {
      valid = false;
      errors['place'] = <Trans>This field is required</Trans>;
    }
    if (!FormAction.validateEmpty(address.country)) {
      valid = false;
      errors['country'] = <Trans>This field is required</Trans>;
    }

    if (valid) {
      setEditAddress(false);

      let response = await APIAction.request({
        method: address.id ? 'PATCH' : 'POST',
        url: '/api/addresses' + (address.id ? `/${address.id}` : ''),
        body: address,
      });

      await APIAction.request({
        method: 'PATCH',
        url: `/api/employees/${props.user.employee.id}`,
        body: {
          address: response['@id'],
        },
      });

      //reload
      if (typeof props.onReload === 'function') {
        props.onReload();
      }
    }

    setAddressError(errors);
  };

  return (
    <>
      {props.user ? (
        <Box style={[mainStyle.boxItem, {marginBottom: 0, flex: 1}]}>
          <Box
            style={{alignItems: 'center', flexDirection: 'row', width: '100%'}}>
            <Box style={{width: '100%'}}>
              <Box style={{flexDirection: 'row'}}>
                <HStack
                  flexGrow={1}
                  flexShrink={1}
                  space={2}
                  justifyContent={'space-between'}
                  alignItems={'center'}>
                  <Text style={{color: 'rgba(0,0,0,0.5)'}}>
                    <Trans>Address</Trans>
                  </Text>
                  {/*editAddress ?
                                        <Pressable onPress={() => {
                                            save();
                                        }}>
                                            <Icon name={'save'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} />
                                        </Pressable>
                                        :
                                        <Pressable onPress={() => {
                                            setEditAddress(true);
                                        }}>
                                            <Icon name={'edit'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} />
                                        </Pressable>
                                    */}
                </HStack>
              </Box>

              {editAddress ? (
                <VStack space={2}>
                  <Stack
                    space={2}
                    direction={{
                      base: 'column',
                      sm: 'row',
                    }}>
                    <Box flex={4}>
                      <Text>
                        <Trans>Street</Trans>
                      </Text>
                      <Input
                        defaultValue={address.street}
                        onChangeText={value => {
                          setAddress({...address, ...{street: value}});
                        }}
                      />
                      {addressError && addressError.street && (
                        <Text style={main.textError}>
                          {addressError.street}
                        </Text>
                      )}
                    </Box>
                    <Box flex={1}>
                      <Text>
                        <Trans>Number</Trans>
                      </Text>
                      <Input
                        defaultValue={address.streetNumber}
                        onChangeText={value => {
                          setAddress({...address, ...{streetNumber: value}});
                        }}
                      />
                      {addressError && addressError.streetNumber && (
                        <Text style={main.textError}>
                          {addressError.streetNumber}
                        </Text>
                      )}
                    </Box>
                    <Box flex={1}>
                      <Text>
                        <Trans>Suite</Trans>
                      </Text>
                      <Input
                        defaultValue={address.suite}
                        onChangeText={value => {
                          setAddress({...address, ...{suite: value}});
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    space={2}
                    direction={{
                      base: 'column',
                      sm: 'row',
                    }}>
                    <Box flex={1}>
                      <Text>
                        <Trans>Zip</Trans>
                      </Text>
                      <Input
                        defaultValue={address.zip}
                        onChangeText={value => {
                          setAddress({...address, ...{zip: value}});
                        }}
                      />
                      {addressError && addressError.zip && (
                        <Text style={main.textError}>{addressError.zip}</Text>
                      )}
                    </Box>
                    <Box flex={1}>
                      <Text>
                        <Trans>City</Trans>
                      </Text>
                      <Input
                        defaultValue={address.place}
                        onChangeText={value => {
                          setAddress({...address, ...{place: value}});
                        }}
                      />
                      {addressError && addressError.place && (
                        <Text style={main.textError}>{addressError.place}</Text>
                      )}
                    </Box>
                  </Stack>
                  <Box>
                    <Text>
                      <Trans>Country</Trans>
                    </Text>
                    <Select
                      selectedValue={address.country}
                      _selectedItem={{
                        endIcon: <CheckIcon size={5} />,
                      }}
                      onValueChange={value => {
                        setAddress({...address, ...{country: value}});
                      }}>
                      {countryOptions}
                    </Select>
                    {addressError && addressError.country && (
                      <Text style={main.textError}>{addressError.country}</Text>
                    )}
                  </Box>
                </VStack>
              ) : (
                <Heading style={[mainStyle.mediumTitle, {fontSize: 14}]}>
                  {fullAddress ? fullAddress : <Trans>Not added</Trans>}
                </Heading>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box flex={1}>
          <Skeleton h={85} />
        </Box>
      )}
    </>
  );
};

export default Address;
