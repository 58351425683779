import React, {useEffect, useState} from 'react';
import {StyleSheet} from 'react-native';
import {
  Box,
  Heading,
  Button,
  Text,
  FormControl,
  Input,
  CheckIcon,
  Checkbox,
} from 'native-base';
import {Select} from '../../../Libs/CustomInputs';
import AppContainer from '../../Components/AppContainer';
import Trans from '../../Components/Trans';
import main from '../../../Assets/Styles/main.json';
import GeneralAction from '../../../Actions/GeneralAction';
import APIAction from '../../../Actions/APIAction';
import {v4 as uuidv4} from 'uuid';
import FormAction from '../../../Actions/FormAction';
import DateTimePicker from '../../../Libs/DateTimePicker';
import TranslationAction from '../../../Actions/TranslationAction';
import {NumberInput} from '../../../Libs/CustomInputs';
import UserAction from '../../../Actions/UserAction';

const mainStyle = StyleSheet.create(main);

const PersonalInfoScreen = ({navigation}) => {
  // Init
  const [user, setUser] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);

  // Personal info fields
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  const [nationality, setNationality] = useState('BE');
  const [placeOfBirth, setPlaceOfBirth] = useState('BE');
  const [genderOptions, setGenderOptions] = useState([]);
  const [gender, setGender] = useState('');
  const [birthDay, setBirthDay] = useState('');
  const [nationalRegistry, setNationalRegistry] = useState('');

  const [statusOptions, setStatusOptions] = useState(false);
  const [status, setStatus] = useState('');

  const [contractTypeOptions, setContractTypeOptions] = useState(false);
  const [contractType, setContractType] = useState('');

  const [languageOptions, setLanguageOptions] = useState(false);
  const [userLanguage, setUserLanguage] = useState('');

  const [dependents, setDependents] = useState(0);
  const [handicapped, setHandicapped] = useState(false);
  const [askAvailabilityByEmail, setAskAvailabilityByEmail] = useState(false);
  const [sharePhone, setSharePhone] = useState(false);

  // Address fields
  const [street, setStreet] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [suite, setSuite] = useState('');
  const [zip, setZip] = useState('');
  const [place, setPlace] = useState('');
  const [country, setCountry] = useState('BE');
  let [addressId, setAddressId] = useState('');

  const [iceFirstName, setIceFirstName] = useState('');
  const [iceLastName, setIceLastName] = useState('');
  const [icePhone, setIcePhone] = useState('');
  const [iceDescription, setIceDescription] = useState('');
  let [iceContactId, setIceContactId] = useState('');

  const [initialLoaded, setInitialLoaded] = useState(false);

  //on load
  useEffect(() => {
    const getData = async () => {
      // Get user
      if (!initialLoaded) {
        let dbUser = await UserAction.getUser();

        // Get countries
        let countryList = await GeneralAction.getCountries();

        let options = [];
        for (const key in countryList) {
          let value = countryList[key];
          options.push(
            <Select.Item key={uuidv4()} label={value} value={key} />,
          );
        }

        setCountryOptions(options);

        let language = await TranslationAction.getSelectedLanguage();

        // Get statuses
        let apiStatuses = await APIAction.request({
          method: 'GET',
          url: '/api/easy_pay_marital_statuses',
        });
        if ('hydra:member' in apiStatuses && language) {
          apiStatuses = apiStatuses['hydra:member'];

          let statusChoices = [];
          for (const key in apiStatuses) {
            let value = apiStatuses[key];
            let id = value.id;
            let label = value.name[language.code];
            statusChoices.push(
              <Select.Item key={uuidv4()} label={label} value={id} />,
            );
          }

          setStatusOptions(statusChoices);
        }

        // Get contract types
        let apiContractTypes = await APIAction.request({
          method: 'GET',
          url: '/api/contract_types',
        });
        if ('hydra:member' in apiContractTypes && language) {
          apiContractTypes = apiContractTypes['hydra:member'];

          let contractTypeChoices = [];
          for (const key in apiContractTypes) {
            let value = apiContractTypes[key];
            let id = value.id;
            let label = value.name[language.code];
            contractTypeChoices.push(
              <Select.Item key={uuidv4()} label={label} value={id} />,
            );
          }

          setContractTypeOptions(contractTypeChoices);
        }

        // Get languages
        let apiLanguages = await APIAction.request({
          method: 'GET',
          url: '/api/languages',
        });
        if ('hydra:member' in apiLanguages) {
          apiLanguages = apiLanguages['hydra:member'];

          let languageChoices = [];
          for (const key in apiLanguages) {
            let value = apiLanguages[key];
            let id = value.id;
            let label = value.code;
            languageChoices.push(
              <Select.Item key={uuidv4()} label={label} value={id} />,
            );
          }

          setLanguageOptions(languageChoices);
        }

        // Get genders
        let genders = await GeneralAction.getGenders();

        let genderChoices = [];
        for (const [key, gender] of Object.entries(genders)) {
          genderChoices.push(
            <Select.Item key={uuidv4()} label={gender} value={gender} />,
          );
        }

        setGenderOptions(genderChoices);

        setDefaultData(dbUser);
        setInitialLoaded(true);
      }
    };

    getData();
  }, [initialLoaded]);

  /**
   * Set default data
   *
   * @param dbUser
   */
  const setDefaultData = dbUser => {
    // Set default data
    if (dbUser) {
      // Set user data
      setUser(dbUser);
      setFirstName(dbUser.firstName);
      setLastName(dbUser.lastName);
      setMobile(dbUser.phone);

      if (dbUser.language) {
        setUserLanguage(dbUser.language.id);
      }

      if (dbUser.employee) {
        // Set employee data
        let employee = dbUser.employee;

        if (employee.placeOfBirth) {
          setPlaceOfBirth(employee.placeOfBirth);
        }

        if (employee.maritalStatus) {
          setStatus(employee.maritalStatus.id);
        }

        if (employee.contractType) {
          setContractType(employee.contractType.id);
        }

        if (employee.nationality) {
          setNationality(employee.nationality);
        }

        if (employee.birthDay) {
          setBirthDay(new Date(employee.birthDay));
        }

        if (employee.dependents) {
          setDependents(employee.dependents);
        }

        if (employee.nationalRegistry) {
          setNationalRegistry(employee.nationalRegistry);
        }

        setHandicapped(employee.handicapped);
        setAskAvailabilityByEmail(employee.askAvailabilityByEmail);
        setSharePhone(employee.sharePhone);
        setGender(employee.gender);

        // Set address data
        if (employee.address) {
          let currentAddress = employee.address;
          setAddressId(currentAddress.id);

          setStreet(currentAddress.street);
          setStreetNumber(currentAddress.streetNumber);
          setZip(currentAddress.zip);
          setPlace(currentAddress.place);

          if (currentAddress.country) {
            setCountry(currentAddress.country);
          }
          if (currentAddress.suite) {
            setSuite(currentAddress.suite);
          }
        }

        // Set ice contact data
        if (employee.iceContact) {
          let currentIceContact = employee.iceContact;
          setIceContactId(currentIceContact.id);

          setIceFirstName(currentIceContact.firstName);
          setIceLastName(currentIceContact.lastName);
          setIcePhone(currentIceContact.phone);
          setIceDescription(currentIceContact.description);
        }
      }

      setInitialLoaded(true);
    }
  };

  /**
   * Form validation
   *
   * @returns {boolean}
   */
  const validateForm = () => {
    // Init
    let error = false;

    // Check if first name is empty
    if (!FormAction.validateEmpty(firstName)) {
      error = true;
      GeneralAction.toast('error', <Trans>Please enter your firstname</Trans>);
    }

    // Check if last name is empty
    if (!FormAction.validateEmpty(lastName)) {
      error = true;
      GeneralAction.toast('error', <Trans>Please enter your lastname</Trans>);
    }

    // Check if mobile is not empty and is a valid number
    if (
      !FormAction.validateEmpty(mobile) ||
      !FormAction.validatePhone(mobile)
    ) {
      error = true;
      GeneralAction.toast('error', <Trans>Please enter a valid mobile</Trans>);
    }

    // Check if national registry is not empty
    if (!FormAction.validateEmpty(nationalRegistry)) {
      error = true;
      GeneralAction.toast(
        'error',
        <Trans>Please enter your national registry</Trans>,
      );
    }

    // Check if birthday is not empty
    if (!FormAction.validateEmpty(birthDay)) {
      error = true;
      GeneralAction.toast('error', <Trans>Please enter your birthdate</Trans>);
    }

    // Check if place of birth is not empty
    if (!FormAction.validateEmpty(placeOfBirth)) {
      error = true;
      GeneralAction.toast(
        'error',
        <Trans>Please select your place of birth</Trans>,
      );
    }

    // Check if status is not empty
    if (!FormAction.validateEmpty(status)) {
      error = true;
      GeneralAction.toast(
        'error',
        <Trans>Please select your marital status</Trans>,
      );
    }

    // Check if contract type is not empty
    if (!FormAction.validateEmpty(contractType)) {
      error = true;
      GeneralAction.toast('error', <Trans>Please select contract type</Trans>);
    }

    // Check if language is not empty
    if (!FormAction.validateEmpty(userLanguage)) {
      error = true;
      GeneralAction.toast('error', <Trans>Please select language</Trans>);
    }

    // Check if nationality is not empty
    if (!FormAction.validateEmpty(nationality)) {
      error = true;
      GeneralAction.toast(
        'error',
        <Trans>Please select your nationality</Trans>,
      );
    }

    // Check if gender is not empty
    if (!FormAction.validateEmpty(gender)) {
      error = true;
      GeneralAction.toast('error', <Trans>Please select your gender</Trans>);
    }

    // Check if street is not empty
    if (!FormAction.validateEmpty(street)) {
      error = true;
      GeneralAction.toast('error', <Trans>Please enter your street</Trans>);
    }

    // Check if street number is not empty
    if (!FormAction.validateEmpty(streetNumber)) {
      error = true;
      GeneralAction.toast('error', <Trans>Please enter your number</Trans>);
    }

    // Check if zip is not empty
    if (!FormAction.validateEmpty(zip)) {
      error = true;
      GeneralAction.toast('error', <Trans>Please enter your zip</Trans>);
    }

    // Check if place is not empty
    if (!FormAction.validateEmpty(place)) {
      error = true;
      GeneralAction.toast('error', <Trans>Please enter your place</Trans>);
    }

    // Check if country is not empty
    if (!FormAction.validateEmpty(country)) {
      error = true;
      GeneralAction.toast('error', <Trans>Please select your country</Trans>);
    }

    // Check if firstname of ice contact  is not empty
    if (!FormAction.validateEmpty(iceFirstName)) {
      error = true;
      GeneralAction.toast(
        'error',
        <Trans>Please enter the first name of your ICE contact</Trans>,
      );
    }

    // Check if lastname of ice contact  is not empty
    if (!FormAction.validateEmpty(iceLastName)) {
      error = true;
      GeneralAction.toast(
        'error',
        <Trans>Please enter the last name of your ICE contact</Trans>,
      );
    }

    // Check if phone of ice contact  is not empty
    if (!FormAction.validateEmpty(icePhone)) {
      error = true;
      GeneralAction.toast(
        'error',
        <Trans>Please enter the phone of your ICE contact</Trans>,
      );
    }

    // Check if description of ice contact  is not empty
    if (!FormAction.validateEmpty(iceDescription)) {
      error = true;
      GeneralAction.toast(
        'error',
        <Trans>Please enter the description of your ICE contact</Trans>,
      );
    }

    return error;
  };

  /**
   * Update user data
   *
   * @returns {Promise<void>}
   */
  const updateUser = async () => {
    let url = '/api/users/' + user.id;
    let language = '/api/languages/' + userLanguage;

    let data = {
      firstName: firstName,
      lastName: lastName,
      phone: mobile,
      language: language,
    };

    await APIAction.request({method: 'PATCH', url: url, body: data});
  };

  /**
   * Update employee data
   *
   * @param address
   * @param iceContact
   * @returns {Promise<void>}
   */
  const updateEmployee = async (address = null, iceContact = null) => {
    let nameStep = 'personalInfo';
    let employeeUrl = '/api/employees/' + user.employee.id;
    let maritalStatus = '/api/easy_pay_marital_statuses/' + status;
    let contract = '/api/contract_types/' + contractType;
    let completedSteps = user.employee.completedSteps;

    // Add step to completed steps
    if (!completedSteps) {
      completedSteps = [];
    }

    if (!completedSteps.includes(nameStep)) {
      completedSteps.push(nameStep);
    }

    let birthDate = birthDay;
    birthDate.setDate(birthDate.getDate() + 1);

    let employeeData = {
      placeOfBirth: placeOfBirth,
      nationality: nationality,
      gender: gender,
      completedSteps: completedSteps,
      birthDay: birthDate,
      maritalStatus: maritalStatus,
      contractType: contract,
      dependents: dependents,
      handicapped: handicapped,
      sharePhone: sharePhone,
      askAvailabilityByEmail: askAvailabilityByEmail,
      nationalRegistry: nationalRegistry,
    };

    if (address) {
      employeeData.address = address;
    }

    if (iceContact) {
      employeeData.iceContact = iceContact;
    }

    await APIAction.request({
      method: 'PATCH',
      url: employeeUrl,
      body: employeeData,
    });
  };

  /**
   * Update address
   *
   * @returns {Promise<*>}
   */
  const updateAddress = async () => {
    // Init
    let addressUrl = '/api/addresses';
    let addressMethod = 'POST';

    // Initial data
    let addressData = {
      street: street,
      streetNumber: streetNumber,
      zip: zip,
      suite: suite,
      place: place,
      country: country,
    };

    if (addressId) {
      // Update params to update address
      addressUrl += '/' + addressId;
      addressMethod = 'PATCH';
    } else {
      // Update params to create address
      addressData.name = 'Default';
    }

    // Update/Create through api
    let response = await APIAction.request({
      method: addressMethod,
      url: addressUrl,
      body: addressData,
    });

    // Return id if new
    if (addressId.length === 0 && response) {
      setAddressId(response.id);
      return response['@id'];
    }

    return null;
  };

  /**
   * Update ice contact
   *
   * @returns {Promise<*>}
   */
  const updateIceContact = async () => {
    // Init
    let url = '/api/ice_contacts';
    let method = 'POST';

    // Initial data
    let data = {
      firstName: iceFirstName,
      lastName: iceLastName,
      phone: icePhone,
      description: iceDescription,
    };

    if (iceContactId) {
      // Update params to update address
      url += '/' + iceContactId;
      method = 'PATCH';
    }

    // Update/Create through api
    let response = await APIAction.request({
      method: method,
      url: url,
      body: data,
    });

    // Return id if new
    if (iceContactId.length === 0 && response) {
      setIceContactId(response.id);
      return response['@id'];
    }

    return null;
  };

  /**
   * Check and update data
   */
  const checkForm = async () => {
    // Check for errors
    let error = validateForm();

    // Save if no errors
    if (!error && user) {
      // Update data
      setDisableBtn(true);
      let newAddress = await updateAddress();
      let newIceContact = await updateIceContact();

      await updateUser();
      await updateEmployee(newAddress, newIceContact);

      // Show success
      //GeneralAction.toast('success', <Trans>Personal info updated</Trans>);

      // Go back to complete profile flow
      setTimeout(() => {
        navigation.goBack();
      }, 2000);
    }
  };

  return (
    <AppContainer noTabBar>
      <Box style={mainStyle.pageTitleWrapper}>
        <Box style={{flexGrow: 1}}>
          <Heading style={mainStyle.pageTitle}>
            <Trans>Personal info</Trans>
          </Heading>
        </Box>
      </Box>

      {/* Input fields */}
      <Box mb={3}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>First name</Trans>
            </Text>
          </FormControl.Label>
          <Input
            w="100%"
            defaultValue={firstName}
            onChangeText={value => setFirstName(value)}
          />
        </FormControl>
      </Box>

      <Box mb={3}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Last name</Trans>
            </Text>
          </FormControl.Label>
          <Input
            w="100%"
            defaultValue={lastName}
            onChangeText={value => setLastName(value)}
          />
        </FormControl>
      </Box>

      <Box mb={3}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Mobile</Trans>
            </Text>
          </FormControl.Label>
          <Input
            w="100%"
            defaultValue={mobile}
            onChangeText={value => setMobile(value)}
          />
        </FormControl>
      </Box>

      <Box mb={3}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>National registry</Trans>
            </Text>
          </FormControl.Label>
          <Input
            w="100%"
            defaultValue={nationalRegistry}
            onChangeText={value => setNationalRegistry(value)}
          />
        </FormControl>
      </Box>

      <Box
        mb={3}
        style={{width: '100%', maxWidth: '100%', paddingVertical: 15}}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Language</Trans>
            </Text>
          </FormControl.Label>
          <Box
            style={{
              textAlign: 'center',
              flexDirection: 'row',
              border: '1px solid rgb(212,212,212)',
              borderRadius: 4,
            }}>
            <Box style={{width: '100%'}}>
              <Select
                selectedValue={userLanguage}
                variant="unstyled"
                w="100%"
                _selectedItem={{
                  bg: '#00aaff',
                  color: '#ffffff',
                  endIcon: <CheckIcon size={5} />,
                }}
                onValueChange={itemValue => setUserLanguage(itemValue)}>
                {languageOptions}
              </Select>
            </Box>
          </Box>
        </FormControl>
      </Box>

      <Box style={{width: '100%', minWidth: '100%', paddingVertical: 15}}>
        <Checkbox
          size="md"
          width="100%"
          style={{alignSelf: 'flex-start'}}
          _hover={{bg: '#00aaff'}}
          _pressed={{bg: '#00aaff'}}
          _checked={{bg: '#00aaff'}}
          borderRadius="50"
          borderWidth="0"
          bg="rgba(255,255,255,0.8)"
          value={1}
          isChecked={sharePhone}
          onPress={value => {
            setSharePhone(value);
          }}
          onChange={value => {
            setSharePhone(value);
          }}>
          <Box style={{flexDirection: 'row', alignItems: 'center'}}>
            <Box>
              <Text>Share phone number with other employees?</Text>
            </Box>
          </Box>
        </Checkbox>
      </Box>

      <Box style={{width: '100%', minWidth: '100%', paddingVertical: 15}}>
        <Checkbox
          size="md"
          width="100%"
          style={{alignSelf: 'flex-start'}}
          _hover={{bg: '#00aaff'}}
          _pressed={{bg: '#00aaff'}}
          _checked={{bg: '#00aaff'}}
          borderRadius="50"
          borderWidth="0"
          bg="rgba(255,255,255,0.8)"
          value={1}
          isChecked={askAvailabilityByEmail}
          onPress={value => {
            setAskAvailabilityByEmail(value);
          }}
          onChange={value => {
            setAskAvailabilityByEmail(value);
          }}>
          <Box style={{flexDirection: 'row', alignItems: 'center'}}>
            <Box>
              <Text>Ask availability by email?</Text>
            </Box>
          </Box>
        </Checkbox>
      </Box>

      <Box style={{width: '100%', maxWidth: '100%', paddingVertical: 15}}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Birthdate</Trans>
            </Text>
          </FormControl.Label>
          <Box
            style={{
              textAlign: 'center',
              flexDirection: 'row',
              border: '1px solid rgb(212,212,212)',
              borderRadius: 4,
            }}>
            <Box style={{width: '100%'}}>
              <DateTimePicker
                mode={'date'}
                value={birthDay}
                onChange={value => {
                  setBirthDay(value);
                }}
              />
            </Box>
          </Box>
        </FormControl>
      </Box>
      <Box style={{width: '100%', maxWidth: '100%', paddingVertical: 15}}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Place of birth</Trans>
            </Text>
          </FormControl.Label>
          <Box
            style={{
              textAlign: 'center',
              flexDirection: 'row',
              border: '1px solid rgb(212,212,212)',
              borderRadius: 4,
            }}>
            <Box style={{width: '100%'}}>
              <Select
                selectedValue={placeOfBirth}
                variant="unstyled"
                w="100%"
                _selectedItem={{
                  bg: '#00aaff',
                  color: '#ffffff',
                  endIcon: <CheckIcon size={5} />,
                }}
                onValueChange={itemValue => setPlaceOfBirth(itemValue)}>
                {countryOptions}
              </Select>
            </Box>
          </Box>
        </FormControl>
      </Box>

      <Box style={{width: '100%', maxWidth: '100%', paddingVertical: 15}}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Nationality</Trans>
            </Text>
          </FormControl.Label>
          <Box
            style={{
              textAlign: 'center',
              flexDirection: 'row',
              border: '1px solid rgb(212,212,212)',
              borderRadius: 4,
            }}>
            <Box style={{width: '100%'}}>
              <Select
                selectedValue={nationality}
                variant="unstyled"
                w="100%"
                _selectedItem={{
                  bg: '#00aaff',
                  color: '#ffffff',
                  endIcon: <CheckIcon size={5} />,
                }}
                onValueChange={itemValue => setNationality(itemValue)}>
                {countryOptions}
              </Select>
            </Box>
          </Box>
        </FormControl>
      </Box>

      <Box style={{width: '100%', maxWidth: '100%', paddingVertical: 15}}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Gender</Trans>
            </Text>
          </FormControl.Label>
          <Box
            style={{
              textAlign: 'center',
              flexDirection: 'row',
              border: '1px solid rgb(212,212,212)',
              borderRadius: 4,
            }}>
            <Box style={{width: '100%'}}>
              <Select
                selectedValue={gender}
                variant="unstyled"
                w="100%"
                _selectedItem={{
                  bg: '#00aaff',
                  color: '#ffffff',
                  endIcon: <CheckIcon size={5} />,
                }}
                onValueChange={itemValue => setGender(itemValue)}>
                {genderOptions}
              </Select>
            </Box>
          </Box>
        </FormControl>
      </Box>

      <Box
        mb={3}
        style={{width: '100%', maxWidth: '100%', paddingVertical: 15}}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Marital status</Trans>
            </Text>
          </FormControl.Label>
          <Box
            style={{
              textAlign: 'center',
              flexDirection: 'row',
              border: '1px solid rgb(212,212,212)',
              borderRadius: 4,
            }}>
            <Box style={{width: '100%'}}>
              <Select
                selectedValue={status}
                variant="unstyled"
                w="100%"
                _selectedItem={{
                  bg: '#00aaff',
                  color: '#ffffff',
                  endIcon: <CheckIcon size={5} />,
                }}
                onValueChange={itemValue => setStatus(itemValue)}>
                {statusOptions}
              </Select>
            </Box>
          </Box>
        </FormControl>
      </Box>

      <Box
        mb={3}
        style={{width: '100%', maxWidth: '100%', paddingVertical: 15}}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Contract Type</Trans>
            </Text>
          </FormControl.Label>
          <Box
            style={{
              textAlign: 'center',
              flexDirection: 'row',
              border: '1px solid rgb(212,212,212)',
              borderRadius: 4,
            }}>
            <Box style={{width: '100%'}}>
              <Select
                selectedValue={contractType}
                variant="unstyled"
                w="100%"
                _selectedItem={{
                  bg: '#00aaff',
                  color: '#ffffff',
                  endIcon: <CheckIcon size={5} />,
                }}
                onValueChange={itemValue => setContractType(itemValue)}>
                {contractTypeOptions}
              </Select>
            </Box>
          </Box>
        </FormControl>
      </Box>

      <Box mb={3}>
        <FormControl>
          <FormControl.Label>
            <Text>
              <Trans>Dependents</Trans>
            </Text>
          </FormControl.Label>
          <NumberInput
            value={dependents}
            onChange={value => setDependents(value)}
          />
        </FormControl>
      </Box>

      <Box
        mb={3}
        style={{width: '100%', minWidth: '100%', paddingVertical: 15}}>
        <Checkbox
          size="md"
          width="100%"
          style={{alignSelf: 'flex-start'}}
          _hover={{bg: '#00aaff'}}
          _pressed={{bg: '#00aaff'}}
          _checked={{bg: '#00aaff'}}
          borderRadius="50"
          borderWidth="0"
          bg="rgba(255,255,255,0.8)"
          value={1}
          isChecked={handicapped}
          onPress={value => {
            setHandicapped(value);
          }}
          onChange={value => {
            setHandicapped(value);
          }}>
          <Box style={{flexDirection: 'row', alignItems: 'center'}}>
            <Box>
              <Text>Are you disabled?</Text>
            </Box>
          </Box>
        </Checkbox>
      </Box>

      {/* ADDRESS */}
      <Box style={mainStyle.pageTitleWrapper}>
        <Box style={{flexGrow: 1}}>
          <Heading style={mainStyle.pageTitle}>
            <Trans>Address</Trans>
          </Heading>
        </Box>
      </Box>

      <Box mb={3}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Street</Trans>
            </Text>
          </FormControl.Label>
          <Input
            w="100%"
            defaultValue={street}
            onChangeText={value => setStreet(value)}
          />
        </FormControl>
      </Box>

      <Box mb={3}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Number</Trans>
            </Text>
          </FormControl.Label>
          <Input
            w="100%"
            defaultValue={streetNumber}
            onChangeText={value => setStreetNumber(value)}
          />
        </FormControl>
      </Box>

      <Box mb={3}>
        <FormControl>
          <FormControl.Label>
            <Text>
              <Trans>Suite</Trans>
            </Text>
          </FormControl.Label>
          <Input
            w="100%"
            defaultValue={suite}
            onChangeText={value => setSuite(value)}
          />
        </FormControl>
      </Box>
      <Box mb={3}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Zip</Trans>
            </Text>
          </FormControl.Label>
          <Input
            w="100%"
            defaultValue={zip}
            onChangeText={value => setZip(value)}
          />
        </FormControl>
      </Box>

      <Box>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>City</Trans>
            </Text>
          </FormControl.Label>
          <Input
            w="100%"
            defaultValue={place}
            onChangeText={value => setPlace(value)}
          />
        </FormControl>
      </Box>

      <Box style={{width: '100%', maxWidth: '100%', paddingVertical: 15}}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Country</Trans>
            </Text>
          </FormControl.Label>
          <Box
            style={{
              textAlign: 'center',
              flexDirection: 'row',
              border: '1px solid rgb(212,212,212)',
              borderRadius: 4,
            }}>
            <Box style={{width: '100%'}}>
              <Select
                selectedValue={country}
                variant="unstyled"
                w="100%"
                _selectedItem={{
                  bg: '#00aaff',
                  color: '#ffffff',
                  endIcon: <CheckIcon size={5} />,
                }}
                onValueChange={itemValue => setCountry(itemValue)}>
                {countryOptions}
              </Select>
            </Box>
          </Box>
        </FormControl>
      </Box>

      {/* ICE CONTACT */}
      <Box style={mainStyle.pageTitleWrapper}>
        <Box style={{flexGrow: 1}}>
          <Heading style={mainStyle.pageTitle}>
            <Trans>ICE contact</Trans>
          </Heading>
        </Box>
      </Box>

      <Box mb={3}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>First name</Trans>
            </Text>
          </FormControl.Label>
          <Input
            w="100%"
            defaultValue={iceFirstName}
            onChangeText={value => setIceFirstName(value)}
          />
        </FormControl>
      </Box>

      <Box mb={3}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Last name</Trans>
            </Text>
          </FormControl.Label>
          <Input
            w="100%"
            defaultValue={iceLastName}
            onChangeText={value => setIceLastName(value)}
          />
        </FormControl>
      </Box>

      <Box mb={3}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Phone</Trans>
            </Text>
          </FormControl.Label>
          <Input
            w="100%"
            defaultValue={icePhone}
            onChangeText={value => setIcePhone(value)}
          />
        </FormControl>
      </Box>

      <Box mb={6}>
        <FormControl isRequired>
          <FormControl.Label>
            <Text>
              <Trans>Description</Trans>
            </Text>
          </FormControl.Label>
          <Input
            w="100%"
            defaultValue={iceDescription}
            onChangeText={value => setIceDescription(value)}
          />
        </FormControl>
      </Box>

      {/* Submit */}
      <Box>
        <Button
          isDisabled={disableBtn}
          isLoading={disableBtn}
          colorScheme={'success'}
          onPress={() => {
            checkForm();
          }}>
          <Text color={'#fff'}>
            <Trans>Done</Trans>
          </Text>
        </Button>
      </Box>
    </AppContainer>
  );
};

export default PersonalInfoScreen;
