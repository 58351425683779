import React, { useState, useEffect } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import {
    Box,
    Divider,
    Text,
    Heading,
    HStack,
    Stack,
    VStack,
    Skeleton,
} from 'native-base';
import main from '../../../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../../Assets/Fontello/line-awesome-config.json';
import TranslationAction from '../../../../Actions/TranslationAction';
import APIAction from '../../../../Actions/APIAction';
import GeneralAction from '../../../../Actions/GeneralAction';
import ClientImage from '../../../Components/ClientImage';
import UserImage from '../../../Components/UserImage';
import Colleague from './Colleague';
import File from './File';
import Mapbox from '../../../../Libs/Mapbox';
import FileFunctions from '../../../../Libs/FileFunctions';
import Trans from '../../../Components/Trans';
import ScaledImage from '../../../Components/ScaledImage';
import CostForm from '../../FillInHours/Components/CostForm';
import { v4 as uuidv4 } from 'uuid';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const JobPreview = props => {
    const job = props.job;

    let [startDay, setStartDay] = useState('');
    let [endDay, setEndDay] = useState('');
    let [address, setAddress] = useState(null);
    let [coordinates, setCoordinates] = useState(null);
    let [logo, setLogo] = useState(null);
    let { height, width } = useWindowDimensions();
    let [image, setImage] = useState(null);
    let [windowSizeUuid, setWindowSizeUuid] = useState(uuidv4());
    let [files, setFiles] = useState([]);
    let [alias, setAlias] = useState(''),
        [clothingAndSupplyRules, setClothingAndSupplyRules] = useState(false),
        [colleagues, setColleagues] = useState([]),
        [colleaguesLoading, setColleaguesLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            // Get language
            if (job) {
                let language = await TranslationAction.getSelectedLanguage();

                //get files
                if (job?.shift?.project?.files) {
                    let files = job.shift.project.files;
                    let fileElements = [];
                    for (let file of files) {
                        fileElements.push(<File key={uuidv4()} file={file} />);
                    }
                    setFiles(fileElements);
                }

                let client = job?.shift?.project?.client;
                if (null === client || undefined === client)
                    client = job?.shift?.workSchedule?.client;

                if (job.shift && job.shift.position) {
                    setAlias(await GeneralAction.getAlias(job.shift.position, client.id));
                }

                //get logo
                if (job.shift?.project?.client.logo) {
                    let logoRes = await FileFunctions.download({
                        url: job.shift.project.client.logo,
                    });
                    setLogo(logoRes);
                }

                //get image
                if (job.image) {
                    let apiImage = await FileFunctions.download({ url: job.image });
                    if (apiImage) setImage(apiImage);
                }

                // Get address
                let addressDetails = null;
                if (job.shift && job.shift.address) {
                    // By default, pick address of shift
                    addressDetails = job.shift.address;
                } else if (job?.shift?.project?.address) {
                    addressDetails = job.shift.project.address;
                } else if (
                    (job.shift && job.shift.project && job.shift.project.client) ||
                    (job.shift && job.shift.workSchedule && job.shift.workSchedule.client)
                ) {
                    if (client.addressBilling) {
                        // Pick billing address of client if we don't have a shift address
                        addressDetails = client.addressBilling;
                    } else if (client.addresses && client.addresses[0]) {
                        // Pick first address of client if no billing address
                        addressDetails = client.addresses[0];
                    }
                }

                let addressString = null;
                if (null !== addressDetails) {
                    addressString = `${addressDetails.street} ${addressDetails.streetNumber
                        }${addressDetails.suite ?? ''}, ${addressDetails.zip} ${addressDetails.place
                        }, `;
                    let countryName = await GeneralAction.getCountry(
                        addressDetails.country,
                    );
                    addressString += countryName;

                    if (addressDetails.coordinates) {
                        setCoordinates(addressDetails.coordinates);
                    }
                }
                setAddress(addressString);

                // Format dates
                if (language) {
                    setStartDay(
                        GeneralAction.formatDateFull(
                            job.shift.startOn,
                            language,
                            false,
                            'numeric',
                            'short',
                            false,
                        ),
                    );
                    setEndDay(
                        GeneralAction.formatDateFull(
                            job.shift.endOn,
                            language,
                            false,
                            'numeric',
                            'short',
                            false,
                        ),
                    );
                }

                // Get clothing and supply rules
                if (job?.shift?.project) {
                    let clothingAndSupplyRules = await APIAction.request({
                        method: 'get',
                        url: `/api/get/clothing_and_supply_rules/${job?.shift?.project?.id}`,
                        cache: false,
                    });

                    setClothingAndSupplyRules(clothingAndSupplyRules);
                }

                // Get colleagues
                let colleagues = await APIAction.request({
                    method: 'get',
                    url: `/api/employee_shifts`,
                    params: {
                        shiftId: job?.shift?.id,
                    },
                    cache: false,
                });
                if (colleagues !== false) {
                    colleagues = colleagues['hydra:member'];
                    let colleaguesArr = [];
                    for (let colleague of colleagues) {
                        colleaguesArr.push(<Colleague user={colleague} />);
                    }
                    setColleagues(colleaguesArr);
                    setColleaguesLoading(false);
                }
            }
        };
        fetchData();
    }, [job]);

    useEffect(() => {
        if (width && height) {
            setWindowSizeUuid(uuidv4());
        }
    }, [width, height]);

    return (
        <>
            <>
                <Box style={mainStyle.boxItemVertical}>
                    <HStack alignItems={'center'} justifyContent={'space-between'}>
                        <Box flexGrow={1} flexShrink={1}>
                            <Text>
                                {GeneralAction.formatDateFull(job.shift.startOn, 'nl')}
                            </Text>
                        </Box>
                        <Box>
                            <HStack>
                                {job.shift.projectStartOn && (
                                    <Text
                                        style={[
                                            mainStyle.timeLabel,
                                            {
                                                marginRight: 5,
                                                backgroundColor: '#edf3ff',
                                                color: '#5A73E7',
                                            },
                                        ]}>
                                        <Icon name={'car'} size={15} />{' '}
                                        {GeneralAction.formatTime(job.shift.projectStartOn)}
                                    </Text>
                                )}
                                <Text style={mainStyle.timeLabel}>
                                    <Icon name={'clock'} size={15} />{' '}
                                    {GeneralAction.formatTime(job.shift.startOn)} -{' '}
                                    {GeneralAction.formatTime(job.shift.endOn)}
                                </Text>
                            </HStack>
                        </Box>
                    </HStack>
                    <Divider my="2" style={mainStyle.dividerStyle} />
                    <Stack
                        direction={{
                            md: 'row',
                            base: 'column',
                        }}
                        space={{
                            md: 2,
                            base: 2,
                        }}>
                        <VStack flex={1}>
                            <HStack alignItems={'center'}>
                                {logo && logo.uri && <ScaledImage width={30} uri={logo.uri} />}
                                <Text style={{ marginLeft: 5 }}>
                                    {job.shift.project?.client.name}
                                    {job.shift.workSchedule?.client.name}
                                </Text>
                            </HStack>
                            <Heading>
                                <Text style={{ color: '#00AAFF' }}>
                                    {job.shift.project?.name}
                                </Text>
                            </Heading>
                            <Heading>{alias}</Heading>
                            {job.shift.projectStartOn && (
                                <VStack style={{ marginVertical: 5 }}>
                                    <Text>
                                        <Icon name={'car'} size={15} /> <Trans>Afspraak om</Trans>{' '}
                                        {GeneralAction.formatTime(job.shift.projectStartOn)}
                                    </Text>
                                    <Text>
                                        <Icon name={'clock'} size={15} />{' '}
                                        <Trans>Uren shift: </Trans>{' '}
                                        {GeneralAction.formatTime(job.shift.startOn)} -{' '}
                                        {GeneralAction.formatTime(job.shift.endOn)}
                                    </Text>
                                    {job.shift.transportationMethodsShouldBeVisible &&
                                        job.transportOption && (
                                            <Text>
                                                <Icon name={'car-side'} size={15} />{' '}
                                                <Trans>Uw vervoersmiddel: </Trans>
                                                {job.transportOption.name}
                                                {job.transportOption.licensePlate && (
                                                    <Text
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: 12,
                                                            marginLeft: 6,
                                                        }}>
                                                        ({job.transportOption.licensePlate})
                                                    </Text>
                                                )}
                                            </Text>
                                        )}
                                </VStack>
                            )}

                            {job.description && (
                                <Box style={{ verticalMargin: 10 }}>
                                    <Text style={mainStyle.infoText}>{job.description}</Text>
                                </Box>
                            )}

                            {job.shift.project?.projectInfo && (
                                <Box style={{ verticalMargin: 10 }}>
                                    <Text style={mainStyle.infoText}>
                                        {job.shift.project.projectInfo}
                                    </Text>
                                </Box>
                            )}

                            {job.shift.position.comment && (
                                <Box style={{ verticalMargin: 10 }}>
                                    <Text style={mainStyle.infoText}>
                                        {job.shift.position.comment}
                                    </Text>
                                </Box>
                            )}
                        </VStack>
                        {(clothingAndSupplyRules.clothingRules?.length > 0 ||
                            clothingAndSupplyRules.supplyRules?.length > 0 ||
                            job.shift.position?.comment) && (
                                <VStack
                                    width={{
                                        base: '100%',
                                        md: '50%',
                                    }}>
                                    <Box
                                        style={{
                                            backgroundColor: '#f9f9f9',
                                            padding: 15,
                                            borderRadius: 5,
                                            flexGrow: 1,
                                        }}>
                                        {clothingAndSupplyRules.clothingRules?.length > 0 && (
                                            <>
                                                <Text
                                                    style={[
                                                        mainStyle.infoText,
                                                        { fontWeight: 'bold', fontSize: 12, marginBottom: 5 },
                                                    ]}>
                                                    <Trans>Dresscode: </Trans>
                                                </Text>
                                                <Box style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                                    {clothingAndSupplyRules.clothingRules?.map(
                                                        clothingRule => {
                                                            return (
                                                                <Text
                                                                    style={[
                                                                        mainStyle.timeLabel,
                                                                        {
                                                                            marginRight: 5,
                                                                            backgroundColor: '#edf3ff',
                                                                            color: '#5A73E7',
                                                                            marginBottom: 5,
                                                                            fontSize: 11,
                                                                        },
                                                                    ]}>
                                                                    {clothingRule}
                                                                </Text>
                                                            );
                                                        },
                                                    )}
                                                </Box>
                                            </>
                                        )}
                                        {clothingAndSupplyRules.supplyRules?.length > 0 && (
                                            <>
                                                <Text
                                                    style={[
                                                        mainStyle.infoText,
                                                        {
                                                            fontWeight: 'bold',
                                                            fontSize: 12,
                                                            marginBottom: 5,
                                                            marginTop: 7,
                                                        },
                                                    ]}>
                                                    <Trans>Voorzie ook zeker volgende zaken: </Trans>
                                                </Text>
                                                <Box style={{ flexDirection: 'row' }}>
                                                    {clothingAndSupplyRules.supplyRules?.map(supplyRule => {
                                                        return (
                                                            <Text
                                                                style={[
                                                                    mainStyle.timeLabel,
                                                                    { marginRight: 5, marginBottom: 5, fontSize: 11 },
                                                                ]}>
                                                                {supplyRule}
                                                            </Text>
                                                        );
                                                    })}
                                                </Box>
                                            </>
                                        )}

                                        {/* Project info */}
                                        {job.shift.position?.comment && (
                                            <>
                                                <Text
                                                    style={[
                                                        mainStyle.infoText,
                                                        {
                                                            fontWeight: 'bold',
                                                            fontSize: 12,
                                                            marginBottom: 5,
                                                            marginTop: 7,
                                                        },
                                                    ]}>
                                                    <Trans>Opmerking:</Trans>
                                                </Text>
                                                <Text style={mainStyle.infoText}>
                                                    {job.shift.position?.comment}
                                                </Text>
                                            </>
                                        )}
                                        {/* {image &&
                          <Box style={mainStyle.imagePreview}>
                              <Center>
                                  <ScaledImage
                                      key={`job-img-${windowSizeUuid}`}
                                      style={{ borderRadius: 10 }}
                                      maxHeight={300}
                                      maxWidth={600}
                                      width={width - 100}
                                      uri={image.uri}
                                  />
                              </Center>
                          </Box>
                      } */}
                                    </Box>
                                </VStack>
                            )}
                    </Stack>
                </Box>

                {colleaguesLoading && <Skeleton h={'20'} style={{ marginBottom: 10 }} />}
                {!colleaguesLoading && colleagues.length > 0 && (
                    <Box style={mainStyle.boxItemVertical}>
                        <VStack width={'100%'} space={2}>
                            <Heading style={mainStyle.mediumTitle}>
                                <Trans>Collega's</Trans>:
                            </Heading>
                            {colleagues}
                        </VStack>
                    </Box>
                )}

                {address && (
                    <Box style={mainStyle.boxItemVertical}>
                        <VStack width={'100%'} space={2}>
                            <HStack>
                                <Heading style={mainStyle.mediumTitle}>
                                    <Trans>Location</Trans>:{' '}
                                </Heading>
                                <Text>{address}</Text>
                            </HStack>
                            {coordinates && (
                                <Mapbox
                                    coordinate={[
                                        parseFloat(coordinates.lng),
                                        parseFloat(coordinates.lat),
                                    ]}
                                    zoom={13}
                                    style={{ height: 300, width: '100%' }}
                                />
                            )}
                        </VStack>
                    </Box>
                )}

                {job?.shift?.project?.files && job.shift.project.files.length > 0 && (
                    <Box style={mainStyle.boxItemVertical}>
                        <VStack width={'100%'} space={2}>
                            <HStack>
                                <Heading style={mainStyle.mediumTitle}>
                                    <Trans>Files</Trans>:
                                </Heading>
                            </HStack>
                            {files}
                        </VStack>
                    </Box>
                )}

                {(job?.shift?.project?.client?.forProject || job?.shift?.workSchedule?.client.forProject) &&
                    <CostForm employeeShift={job} />
                }
            </>
        </>
    );
};

export default JobPreview;
