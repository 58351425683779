import React, {useEffect, useState} from 'react';
import {Linking, Platform, StyleSheet} from 'react-native';
import {
  Box,
  Text,
  Heading,
  Pressable,
  Image,
  Input,
  CheckIcon,
} from 'native-base';
import {Select} from '../../../Libs/CustomInputs';
import {useNavigation} from '@react-navigation/native';
import main from '../../../Assets/Styles/main.json';
import {createIconSetFromFontello} from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import Trans from '../../Components/Trans';
import GeneralAction from '../../../Actions/GeneralAction';
import Settings from './Settings';
import APIAction from '../../../Actions/APIAction';
import {ImagePicker} from '../../../Libs/FilePicker';
import {v4 as uuidv4} from 'uuid';
import FormAction from '../../../Actions/FormAction';
import FileFunctions from '../../../Libs/FileFunctions';
import {getName} from 'i18n-iso-countries';
import TranslationAction from '../../../Actions/TranslationAction';
import UserAction from '../../../Actions/UserAction';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const Profile = props => {
  // Init
  const navigation = useNavigation();

  let [uploaderOpen, setUploaderOpen] = useState(false);
  let [profileElements, setProfileElements] = useState([]);
  let [user, setUser] = useState(props.user);
  let [imageChanged, setImageChanged] = useState(true);
  let [source, setSource] = useState(
    require('../../../Assets/Images/user-1.png'),
  );
  let [language, setLanguage] = useState(null);

  // Params for mobile field
  let [editMobile, setEditMobile] = useState(false);
  let [phone, setPhone] = useState(props.user.phone);
  let [phoneError, setPhoneError] = useState('');

  // Params for IBAN field
  let [editIban, setEditIban] = useState(false);
  let [iban, setIban] = useState(props.user.employee.iban);
  let [ibanError, setIbanError] = useState('');

  // Params for address fields
  let [editAddress, setEditAddress] = useState(false);
  let [street, setStreet] = useState('');
  let [streetnumber, setStreetnumber] = useState('');
  let [zip, setZip] = useState('');
  let [place, setPlace] = useState('');
  let [country, setCountry] = useState('');
  let [addressId, setAddressId] = useState('');
  let [addressError, setAddressError] = useState([]);
  let [fullCountry, setFullCountry] = useState('');
  let [initialData, setInitialData] = useState(true);

  const [countries, setCountries] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);

  // Only generate keys once (so elements are not considered a new elements)
  let [keys, setKeys] = useState({
    name: uuidv4(),
    phone: uuidv4(),
    email: uuidv4(),
    settings: uuidv4(),
    address: uuidv4(),
    iban: uuidv4(),
  });

  /**
   * Upload profile picture
   *
   * @param response
   * @returns {Promise<void>}
   */
  let upload = async response => {
    // Upload file
    let file = await APIAction.request({
      method: 'post',
      url: '/api/file/upload',
      body: {
        file: response,
      },
      type: 'multipart/form-data',
    });

    // Link to entity
    if (file && file.id.length) {
      // Update profile image on screen
      let updateUrl = '/api/users/update/image';
      await APIAction.request({
        method: 'PATCH',
        url: updateUrl,
        body: {
          imageId: file.id,
          userId: user.id,
        },
      });

      let currentUser = await UserAction.getUser();
      setUser(currentUser);
      setImageChanged(true);
      setUploaderOpen(false);
    }
  };

  /**
   * Function to validate phone number
   */
  let validatePhone = async () => {
    if (editMobile) {
      // Validate mobile with regex
      if (FormAction.validatePhone(phone)) {
        // Update user with new phone number
        setPhoneError('');
        setEditMobile(!editMobile);
        await updateUser();
      } else {
        // Show error
        setPhoneError(<Trans>Please enter a valid phone number</Trans>);
      }
    } else {
      setEditMobile(!editMobile);
    }

    // Only update one field at a time
    setEditAddress(false);
    setEditIban(false);
  };

  /**
   * Function to validate phone number
   */
  let validateIban = async () => {
    if (editIban) {
      // Validate iban with regex
      let trimmedIban = iban.replace(/\s/g, '');

      if (FormAction.validateIban(trimmedIban)) {
        // Update user with new iban
        setIbanError('');
        setEditIban(!editIban);
        await updateEmployee();
      } else {
        // Show error if invalid
        setIbanError(<Trans>Please enter a valid IBAN number</Trans>);
      }
    } else {
      setEditIban(!editIban);
    }

    // Only update one field at a time
    setEditAddress(false);
    setEditMobile(false);
  };

  /**
   * Function to validate address
   */
  let validateAddress = async () => {
    // Init
    let errors = [];
    let hasErrors = false;

    if (editAddress) {
      // Check if not empty
      if (!FormAction.validateEmpty(street)) {
        errors.street = <Trans>This field is required</Trans>;
        hasErrors = true;
      }

      if (!FormAction.validateEmpty(streetnumber)) {
        errors.streetnumber = <Trans>This field is required</Trans>;
        hasErrors = true;
      }

      if (!FormAction.validateEmpty(zip)) {
        errors.zip = <Trans>This field is required</Trans>;
        hasErrors = true;
      }

      if (!FormAction.validateEmpty(place)) {
        errors.place = <Trans>This field is required</Trans>;
        hasErrors = true;
      }

      if (!FormAction.validateEmpty(country)) {
        errors.country = <Trans>This field is required</Trans>;
        hasErrors = true;
      }

      if (!hasErrors) {
        // Update user with new phone number
        setAddressError([]);
        setEditAddress(!editAddress);

        // Add/ update address
        if (addressId) {
          await updateAddress();
        } else {
          await updateAddress(true);
        }
      } else {
        // Show error if invalid
        setAddressError(errors);
      }
    } else {
      setEditAddress(!editAddress);
    }

    // Update only on field at a time
    setEditMobile(false);
    setEditIban(false);
  };

  /**
   * Function to update a user
   *
   * @returns {Promise<void>}
   */
  let updateUser = async () => {
    // Init
    let url = '/api/users/' + user.id;
    let data = {
      phone: phone,
    };

    // Update through api
    await APIAction.request({method: 'PATCH', url: url, body: data});
  };

  /**
   * Function to update an address
   *
   * @returns {Promise<void>}
   */
  let updateAddress = async (create = false) => {
    // Init
    let url = '/api/addresses';
    let method = 'POST';

    // Initial data
    let data = {
      street: street,
      streetNumber: streetnumber,
      zip: zip,
      place: place,
      country: country,
    };

    if (!create) {
      // Update params to update address
      url += '/' + addressId;
      method = 'PATCH';
    } else {
      // Update params to create address
      data.name = 'Default';
    }

    // Update/Create through api
    let response = await APIAction.request({
      method: method,
      url: url,
      body: data,
    });

    // Link address to user if new
    if (create && response) {
      setAddressId(response.id);
      await updateEmployee({
        address: response['@id'],
      });
    }

    setFullCountry(getName(country, language.code));
  };

  /**
   * Function to update an employee
   *
   * @returns {Promise<void>}
   */
  let updateEmployee = async (data = null) => {
    // Init
    let url = '/api/employees/' + user.employee.id;

    // Set data if not defined
    if (!data) {
      data = {
        iban: iban,
      };
    }

    // Update through api
    await APIAction.request({method: 'PATCH', url: url, body: data});
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      // Get current language
      setLanguage(await TranslationAction.getSelectedLanguage());

      if (!fullCountry && country && language) {
        setFullCountry(getName(country, language.code));
      }

      let elements = [];

      // Get countries
      if (countries && countryOptions.length === 0) {
        let options = [];
        for (const key in countries) {
          let value = countries[key];
          options.push(
            <Select.Item key={uuidv4()} label={value} value={key} />,
          );
        }

        setCountryOptions(options);
      } else {
        if (!countries) {
          let countryList = await GeneralAction.getCountries();
          setCountries(countryList);
        }
      }

      // Get profile picture
      if (user.image && imageChanged) {
        let fileEntity = await FileFunctions.download({url: user.image});
        if (isMounted) {
          if (fileEntity) {
            setSource({
              uri: fileEntity.uri,
            });
            setImageChanged(false);
          }
        }
      }

      if (user) {
        // Name element
        if (user.firstName && user.lastName) {
          elements.push(
            <Box key={keys.name} style={mainStyle.profileWrapper}>
              <Pressable
                style={{
                  flexGrow: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() => setUploaderOpen(true)}>
                {imageChanged && (
                  <Image
                    alt={user.firstName + ' ' + user.lastName}
                    style={{
                      resizeMode: 'cover',
                      width: 100,
                      height: 100,
                      borderRadius: 50,
                      borderColor: '#ffffff',
                      borderWidth: 8,
                      borderStyle: 'solid',
                      marginBottom: 5,
                    }}
                    source={source}
                  />
                )}
                {!imageChanged && (
                  <Image
                    alt={user.firstName + ' ' + user.lastName}
                    style={{
                      resizeMode: 'cover',
                      width: 100,
                      height: 100,
                      borderRadius: 50,
                      borderColor: '#ffffff',
                      borderWidth: 8,
                      borderStyle: 'solid',
                      marginBottom: 5,
                    }}
                    source={source}
                  />
                )}
              </Pressable>
              <Box
                style={{
                  flexGrow: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Heading style={mainStyle.profileName}>
                  {user.firstName} {user.lastName}
                </Heading>
              </Box>
            </Box>,
          );
        }

        elements.push(<Settings key={keys.settings} user={user} />);

        // Email element
        if (user.email) {
          elements.push(
            <Box key={keys.email} style={mainStyle.boxItem}>
              <Box
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  width: '100%',
                }}>
                <Box style={{width: '100%'}}>
                  <Text style={{color: 'rgba(0,0,0,0.5)'}}>E-mail</Text>
                  <Heading style={[mainStyle.mediumTitle, {fontSize: 14}]}>
                    {user.email}
                  </Heading>
                </Box>
                {/* <Box style={{ width: '45%', alignItems: 'center', paddingLeft: 10 }}>
                <Pressable
                  style={[mainStyle.actionButton, { backgroundColor: '#ddf4f0' }]}
                  onPress={async () => {
                    // Mail user
                    let url = 'mailto:' + GeneralAction.trim(user.email);
                    await Linking.openURL(url);
                  }}
                >
                  <Box style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'center',
                  }}>
                    <Box style={{ paddingEnd: 2 }}>
                      <Icon name={'envelope'} size={16} style={{ color: '#2ebfa5' }} />
                    </Box>
                    <Box>
                      <Text style={{ color: '#2ebfa5', fontSize: 13 }}><Trans>Write
                        now</Trans></Text>
                    </Box>
                  </Box>
                </Pressable>
              </Box> */}
              </Box>
            </Box>,
          );
        }

        // Phone element
        //if (phone) {
        elements.push(
          <Box key={keys.phone} style={mainStyle.boxItem}>
            <Box
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
              }}>
              <Box style={{width: '100%'}}>
                <Box style={{flexDirection: 'row'}}>
                  <Text style={{color: 'rgba(0,0,0,0.5)', width: '95%'}}>
                    <Trans>Mobile</Trans>
                  </Text>
                  <Pressable
                    onPress={async () => {
                      await validatePhone();
                    }}>
                    <Icon
                      name={!editMobile ? 'edit' : 'save'}
                      size={20}
                      style={{color: 'rgba(0,0,0,0.5)'}}
                    />
                  </Pressable>
                </Box>
                {!editMobile && (
                  <Heading style={[mainStyle.mediumTitle, {fontSize: 14}]}>
                    {phone ? phone : <Trans>Not added</Trans>}
                  </Heading>
                )}
                {editMobile && (
                  <>
                    <Input
                      w="100%"
                      defaultValue={phone}
                      onChangeText={value => setPhone(value)}
                    />
                    {phoneError !== '' && (
                      <Text style={main.textError}>{phoneError}</Text>
                    )}
                  </>
                )}
              </Box>

              {/*<Box style={{ width: '45%', alignItems: 'center', paddingLeft: 10 }}>
                  <Pressable
                    style={[mainStyle.actionButton, { backgroundColor: '#e9f5fb' }]}
                    onPress={async () => {
                      // Call user
                      let url = 'tel:' + GeneralAction.trim(user.phone);
                      await Linking.openURL(url);
                    }}
                  >
                    <Box style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                      justifyContent: 'center',
                    }}>
                      <Box style={{ paddingEnd: 2 }}>
                        <Icon name={'phone'} size={16} style={{ color: '#00aaff' }} />
                      </Box>
                      <Box>
                        <Text style={{ color: '#00aaff', fontSize: 13 }}><Trans>Call
                          now</Trans></Text>
                      </Box>
                    </Box>
                  </Pressable>
                </Box> */}
            </Box>
          </Box>,
        );
        //}

        //if (iban) {
        elements.push(
          <Box key={keys.iban} style={mainStyle.boxItem}>
            <Box
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
              }}>
              <Box style={{width: '100%'}}>
                <Box style={{flexDirection: 'row'}}>
                  <Text style={{color: 'rgba(0,0,0,0.5)', width: '95%'}}>
                    <Trans>IBAN</Trans>
                  </Text>
                  <Pressable
                    onPress={async () => {
                      await validateIban();
                    }}>
                    <Icon
                      name={!editIban ? 'edit' : 'save'}
                      size={20}
                      style={{color: 'rgba(0,0,0,0.5)'}}
                    />
                  </Pressable>
                </Box>
                {!editIban && (
                  <Heading style={[mainStyle.mediumTitle, {fontSize: 14}]}>
                    {iban ? iban : <Trans>Not added</Trans>}
                  </Heading>
                )}
                {editIban && (
                  <>
                    <Input
                      w="100%"
                      defaultValue={iban}
                      onChangeText={value => setIban(value)}
                    />
                    {ibanError !== '' && (
                      <Text style={main.textError}>{ibanError}</Text>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>,
        );
        //}
      }

      // Address element
      if (initialData) {
        if (user.employee && user.employee.address) {
          let address = user.employee.address;
          setStreet(address.street);
          setStreetnumber(address.streetNumber);
          setPlace(address.place);
          setZip(address.zip);
          setCountry(address.country);
        } else {
          setCountry('BE');
        }

        setInitialData(false);
      }

      //if (user.employee) {
      //if (user.employee.address) {
      let fullAddress = '';

      if (street && streetnumber && place && zip && fullCountry) {
        fullAddress =
          street +
          ' ' +
          streetnumber +
          ', ' +
          zip +
          ' ' +
          place +
          ', ' +
          fullCountry;
      }

      elements.push(
        <Box key={keys.address} style={mainStyle.boxItem}>
          <Box
            style={{alignItems: 'center', flexDirection: 'row', width: '100%'}}>
            <Box style={{width: '100%'}}>
              <Box style={{flexDirection: 'row'}}>
                <Text style={{color: 'rgba(0,0,0,0.5)', width: '95%'}}>
                  <Trans>Address</Trans>
                </Text>
                <Pressable
                  onPress={async () => {
                    await validateAddress();
                  }}>
                  <Icon
                    name={!editAddress ? 'edit' : 'save'}
                    size={20}
                    style={{color: 'rgba(0,0,0,0.5)'}}
                  />
                </Pressable>
              </Box>

              {!editAddress && (
                <Heading style={[mainStyle.mediumTitle, {fontSize: 14}]}>
                  {fullAddress ? fullAddress : <Trans>Not added</Trans>}
                </Heading>
              )}
              {editAddress && (
                <>
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <Box style={{width: '100%', paddingVertical: 10}}>
                      <Text>
                        <Trans>Street</Trans>
                      </Text>
                      <Input
                        w="100%"
                        defaultValue={street}
                        onChangeText={value => setStreet(value)}
                      />
                      {addressError && addressError.street && (
                        <Text style={main.textError}>
                          {addressError.street}
                        </Text>
                      )}
                    </Box>
                  </Box>
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <Box style={{width: '100%', paddingVertical: 10}}>
                      <Text>Number</Text>
                      <Input
                        w="100%"
                        defaultValue={streetnumber}
                        onChangeText={value => setStreetnumber(value)}
                      />
                      {addressError && addressError.streetnumber && (
                        <Text style={main.textError}>
                          {addressError.streetnumber}
                        </Text>
                      )}
                    </Box>
                  </Box>
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <Box style={{width: '100%', paddingVertical: 10}}>
                      <Text>
                        <Trans>Zip</Trans>
                      </Text>
                      <Input
                        w="100%"
                        defaultValue={zip}
                        onChangeText={value => setZip(value)}
                      />
                      {addressError && addressError.zip && (
                        <Text style={main.textError}>{addressError.zip}</Text>
                      )}
                    </Box>
                  </Box>
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <Box style={{width: '100%', paddingVertical: 10}}>
                      <Text>
                        <Trans>City</Trans>
                      </Text>
                      <Input
                        w="100%"
                        defaultValue={place}
                        onChangeText={value => setPlace(value)}
                      />
                      {addressError && addressError.place && (
                        <Text style={main.textError}>{addressError.place}</Text>
                      )}
                    </Box>
                  </Box>
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <Box style={{width: '100%', paddingVertical: 10}}>
                      <Text>
                        <Trans>Country</Trans>
                      </Text>
                      <Box
                        style={{
                          textAlign: 'center',
                          flexDirection: 'row',
                          border: '1px solid rgb(212,212,212)',
                          borderRadius: 4,
                        }}>
                        <Box style={{width: '100%'}}>
                          <Select
                            selectedValue={country}
                            variant="unstyled"
                            w="100%"
                            _selectedItem={{
                              bg: '#00aaff',
                              color: '#ffffff',
                              endIcon: <CheckIcon size={5} />,
                            }}
                            onValueChange={itemValue => setCountry(itemValue)}>
                            {countryOptions}
                          </Select>
                        </Box>
                      </Box>
                      {addressError && addressError.country && (
                        <Text style={main.textError}>
                          {addressError.country}
                        </Text>
                      )}
                    </Box>
                  </Box>
                </>
              )}
            </Box>
            {/*<Box style={{ width: '45%', alignItems: 'center', paddingLeft: 10 }}>
                  <Pressable
                    style={[mainStyle.actionButton, { backgroundColor: '#e8eefc' }]}
                    onPress={() => {
                      // Open map with address of user
                      let fullAddress = address.street + ' ' + address.streetNumber + ', ' + address.zip + ' ' + address.place;
                      let os = Platform.OS;
                      let url;

                      if (os !== 'web') {
                        url = Platform.select({
                          ios: `maps:0,0?q=${fullAddress}`,
                          android: `geo:0,0?q=${fullAddress}`,
                        });
                      } else {
                        url = 'https://www.google.be/maps/place/' + fullAddress;
                      }

                      Linking.openURL(url);
                    }}
                  >
                    <Box style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                      justifyContent: 'center',
                    }}>
                      <Box style={{ paddingEnd: 2 }}>
                        <Icon name={'location-arrow'} size={16} style={{ color: '#556ee6' }} />
                      </Box>
                      <Box>
                        <Text style={{ color: '#556ee6', fontSize: 13 }}><Trans>View on
                          map</Trans></Text>
                      </Box>
                    </Box>
                  </Pressable>
                </Box> */}
          </Box>
        </Box>,
      );
      //}
      //}

      setProfileElements(elements);
    };

    fetchData();

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigation,
    user,
    imageChanged,
    source,
    editMobile,
    phone,
    phoneError, // Phone dependencies
    editIban,
    iban,
    ibanError, // Iban dependencies
    editAddress,
    street,
    streetnumber,
    zip,
    place,
    addressError,
    addressId,
    country,
    fullCountry,
    language,
    initialData,
  ]);

  return (
    <>
      {profileElements}
      <ImagePicker
        open={uploaderOpen}
        onChange={response => upload(response)}
        onTrigger={value => setUploaderOpen(value)}
      />
    </>
  );
};

export default Profile;
